import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ElementRef, NgZone} from '@angular/core';

import { ColDef, ColGroupDef, FilterModel, GridApi, GridOptions, GridReadyEvent, GridState, ICellRendererParams, IDatasource, IGetRowsParams, PaginationState, RowHeightParams, SortModelItem, ValueFormatterParams } from 'ag-grid-community';
import { formatCurrency } from '@angular/common';
import { Observable } from 'rxjs';

export interface AgTableState {
  filter?: FilterModel;
  sort?: SortModelItem[];
  pagination?: PaginationState;
  top?: number;
  rowSelection?: string[];
}

export interface IAsyncDatasource extends IDatasource {
  updateRowCount(): Observable<any>;
  restoreState?: AgTableState;
}

@Component({
  selector: 'ag-datatable',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ag-datatable.component.html',
  styleUrls: ['./ag-datatable.component.scss']
})
export class AgDatatableComponent implements OnInit {
  gridApi!: GridApi;

  @Input() context: any;
  _columns: ColDef[] =[];
  @Input() 
  get columns() {return this._columns}
  set columns(c: (ColDef | ColGroupDef)[]) {
    this._columns=[...c];
    this.updateRenderers();
  }
  @Input() rowData: any[] = undefined;
  @Input() selectionType: 'single' |'multiple' = 'single';
  @Input() getRowClass: (row)=>string = undefined;
  @Input() isRowSelectable: (row)=>boolean = undefined;
  
  @Input() rowHeight: number = 40;
  @Input() getRowHeight?: (params: RowHeightParams) => number | undefined | null;

  @Input() dataSource: IAsyncDatasource = undefined;

  @Output() view = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() state = new EventEmitter<any>();
  @Output() ready = new EventEmitter<any>();

  static dateFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });

  static filterModelToQry(filterModel: FilterModel): string[] {
    var qry = [];
    for (const key in filterModel) {
      const filter = filterModel[key];
      if (filter.filterType == 'text') {
        qry.push(`${key}~${filter.filter}`);
      } else 
      if (filter.filterType == 'date') {
        var dateFrom = filter.dateFrom ? new Date(filter.dateFrom).toISOString() : '';
        var dateTo = filter.dateTo ? new Date(filter.dateTo).toISOString() : '';
        if (filter.type == 'equals')
          qry.push(`${key}~${dateFrom}`);
        else if (filter.type == 'lessThan')
          qry.push(`${key}<=~${dateTo}`);
        else if (filter.type == 'greaterThan')
          qry.push(`${key}>=~${dateFrom}`);
        else if (filter.type == 'inRange'){
          qry.push(`${key}>=~${dateFrom}`);
          qry.push(`${key}<=~${dateTo}`);
        }
      }
    }
    return qry;
  }

  constructor(
    private ngZone: NgZone,
    private elementRef: ElementRef,
  ) { 
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    if (this.dataSource) {
      this.dataSource.updateRowCount().subscribe(
        n => {
          this.gridApi.setGridOption('datasource', this.dataSource);
          this.gridApi.setRowCount(n);
        }
      );
    }
    this.ready.emit(this);
    this.gridApi.autoSizeAllColumns();
    this.gridApi.sizeColumnsToFit();
  }

  gridOptions: GridOptions;

  static readonly cellRenderers = {
    viewCell: (p: ICellRendererParams) => p.data?'<i class="fa fa-solid fa-eye fa-lg cursor-pointer"></i>':'',
    boolCell: (p: ICellRendererParams) => p.value ? 'Yes' : 'No',
    filterCell: (p: ICellRendererParams) => {
      if (!p.value)
        return p.value;
      return `${p.value}<i class="fa fa-solid fa-filter fa-lg cursor-pointer hover ps-2"></i>`
    },
    currencyCell: (p: ICellRendererParams) => formatCurrency(p.value ?? 0, 'en-us', '$'),
    linkCell: (p: ICellRendererParams) => {
      const href = p["href"] ? p["href"](p.data):"#";
      const text = p["text"] ? p["text"](p.data):p.value;
      if (!text)
        return text;
      return href?`<a href="${href}" viewcell onclick="event.preventDefault()">${text}</a>`:text;
    },
  }

  static readonly valueFormatters = {
    currency: (params: ValueFormatterParams<any, number>) =>
      formatCurrency(params.value ?? 0, 'en-us', '$')
  };

  updateRenderers() {
    this._columns.forEach(c => {
      if (typeof c.cellRenderer === 'string') {
        if (AgDatatableComponent.cellRenderers[c.cellRenderer])
          c.cellRenderer = AgDatatableComponent.cellRenderers[c.cellRenderer];
        }
      if (typeof c.valueFormatter === 'string') {
        if (AgDatatableComponent.valueFormatters[c.valueFormatter])
          c.valueFormatter = AgDatatableComponent.valueFormatters[c.valueFormatter];
      }
    });
  }

  ngOnInit(): void {
    this.gridOptions = {
      context: this.context,
      columnDefs: this._columns,
      rowData: this.rowData,
      rowHeight: this.rowHeight,
      getRowHeight: this.getRowHeight,
      rowSelection: this.selectionType,
      suppressRowClickSelection: true,
      isRowSelectable: this.isRowSelectable,
      getRowClass: this.getRowClass,
      onCellClicked: (event) => {
        this.onCellClicked(event);
      },
      onSelectionChanged: (event) => {
        this.select.emit(event.api.getSelectedRows());
      },
      onStateUpdated: (event) => {
        this.state.emit(event);
      },
      enableCellTextSelection: true,
      suppressDragLeaveHidesColumns: true,
      animateRows: false,
      cacheQuickFilter: true,
      defaultColDef: {
        sortable: true,
        editable: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        suppressHeaderMenuButton: true,
        filterParams: {
          buttons: ['clear']
        }
      },
    };
    if (this.dataSource) {
      this.gridOptions.pagination = true;
      this.gridOptions.paginationPageSizeSelector = [100,500, 1000];
      this.gridOptions.paginationPageSize = 1000;
      this.gridOptions.cacheBlockSize = 1000;
      this.gridOptions.maxBlocksInCache = 1;
      this.gridOptions.rowModelType = 'infinite';
      this.gridOptions.onBodyScroll = (event) => {
        this.state.emit(event);
      }
    }
    this.updateRenderers();
  }

  onCellClicked(event: any) {
    if (event.event.target.tagName == 'I' && event.event.target.classList.contains('fa-filter')) {
      this.gridApi.setFilterModel({[event.column.colId]:{filterType:'text', type:'contains', filter:event.value}});
      return;
    }
    if (event.event.target.tagName == 'I' && event.event.target.classList.contains('fa-eye')) {
      this.view.emit(event);
      return;
    }
    if (event.event.target.tagName == 'A' && event.event.target.attributes["viewcell"]) {
      this.view.emit(event);
      return;
    }
    this.rowClick.emit(event);
  }

  getState(): AgTableState {
    const state: GridState = this.gridApi.getState();
    // const row1 = this.gridApi.getFirstDisplayedRowIndex(); // this is not working
    const headerRect = (this.elementRef.nativeElement.querySelector('.ag-header').getBoundingClientRect());
    const remSize = parseInt(getComputedStyle(document.body).fontSize);
    const firstVisible = document.elementFromPoint(headerRect.x + remSize, (headerRect.y + headerRect.height + remSize))
    const row1 = Number.parseInt( firstVisible.closest('.ag-row')?.getAttribute('row-index')??"0");
    return {
      filter: state?.filter?.filterModel,
      sort:state.sort?.sortModel,
      pagination: state?.pagination,
      top: row1,
      rowSelection: state?.rowSelection as string[],
    }
  }

  restoreState(p: AgTableState) {
    if (!p)
      return;
    if (p.filter)
      this.gridApi.setFilterModel(p.filter);
    if (p.sort)
      this.gridApi.applyColumnState({state:p.sort, defaultState: {sort: null}});
    if (p.pagination) { 
      if (p.pagination?.pageSize)
        this.gridApi.updateGridOptions({ paginationPageSize: p.pagination.pageSize });
      if (p.pagination.page != this.gridApi.paginationGetCurrentPage())
      this.gridApi.paginationGoToPage(p.pagination.page);
    }
    if (p.top)
      this.gridApi.ensureIndexVisible(p.top,"top");
    if (p.rowSelection)
      p.rowSelection.forEach(r => this.gridApi.getRowNode(r).setSelected(true));
  }

  stageState(ds: IAsyncDatasource, params: IGetRowsParams): boolean {
    if (!ds.restoreState)
      return false;
    if (ds.restoreState.filter || ds.restoreState.sort) {
      params.successCallback(new Array(params.endRow - params.startRow).fill({}), ds.rowCount)
      this.restoreState(ds.restoreState);
      ds.restoreState.filter = undefined;
      ds.restoreState.sort = undefined;
      return true;
    }
    if (ds.restoreState.pagination.page != 0) {
      params.successCallback(new Array(params.endRow - params.startRow).fill({}), ds.rowCount);
      setTimeout(() => {
        this.restoreState(ds.restoreState)
        ds.restoreState = undefined;
      });
      return true;
    }
    ds.restoreState = undefined;
    return false;
  }
}
