import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { AgDatatableComponent } from '..';
import { FavAccount } from "src/app/core/models/fav-account";
import { nameof } from "ts-simple-nameof";
import { FavoritesService, Util } from "src/app/core";
import {  switchMap } from "rxjs/operators";
import { ModalService } from "../../modal/modal.service";
import { MfkString, emptyMfk, stringify } from "@uiowa/uiowa-mfk";
import { of } from "rxjs";
import { CellClickedEvent, ColDef, RowClassParams } from "ag-grid-community";

export const MfkFields = [
  "Fund",
  "Org",
  "Dept",
  "Subdept",
  "Grant/Pgm",
  "Iact",
  "Oact",
  "Dact",
  "Fn",
  "Cctr",
];

class FavAcctRow extends FavAccount {
  constructor(a:FavAccount) {
    super();
    Util.copy(a, this);
    a.account.split("-").forEach((s,i)=>(<any>this)[i]=s);
  }
}

@Component({
  selector: 'lib-fav-accnts',
  templateUrl: "./fav-accnts.component.html",
  styleUrls: ["./fav-accnts.component.scss"]
})
export class FavAccnts implements OnInit {
  @Input() fields: string[];
  @Input() newFavorite: string = undefined;
  @Output() OnSelect: EventEmitter<any> = new EventEmitter();
  
  rows: FavAcctRow[];
  cols: ColDef[];
  constructor(
    private favSvc: FavoritesService,
    private modal: ModalService
  ) { }
  
  @ViewChild('datatable') datatable: AgDatatableComponent;
  @ViewChild('editDelTmpl', { static: true }) editDelTmpl: TemplateRef<any>;
  
  show=false;
  ngOnInit(): void {
    const em = Util.getEM();
    if (!this.fields)
      this.fields = MfkFields;
    this.cols = [
      {
        width: 6 * em,
        maxWidth: 6 * em,
        minWidth: 6 * em,
        sortable: false,
        filter: false,
        resizable: false,
        cellRenderer: () => `
        <i class="fa fa-edit mx-1 cursor-pointer" title="Edit"></i>
        <i class="fa fa-trash mx-1 cursor-pointer" title="Delete"></i>
        `,
        onCellClicked: (p) => {
          if ((p.event.target as HTMLElement).classList.contains("fa-edit")) {
            this.Edit(p.data);
          } else if ((p.event.target as HTMLElement).classList.contains("fa-trash")) {
            this.Del(p.data);
          }
        }
      },
      {
        headerName: "Description", width:300,  field: nameof<FavAcctRow>(x => x.description) }
      ];
      this.cols= this.cols.concat(this.fields.map((f, i) => { return { 
        headerName: f, 
        field: i.toString(),
        resizable:false,
        suppressAutoSize:true
        //      $$valueGetter: ((r:FavAcctRow) =>r.elements[i])
      };
    }));
    this.favSvc.accounts$.subscribe(accounts => setTimeout(()=>this.updateAccounts(accounts)));
    
    if (this.newFavorite)
      setTimeout(()=>this.Edit(null));
  }
  
  onGridReady($event: any) {
    this.datatable.gridApi.setGridOption('domLayout', 'print')
    this.datatable.gridApi.setGridOption("rowData", this.rows);
  }

  updateAccounts(accnts:FavAcctRow[]) {
    const em = Util.getEM();
    this.rows = [...accnts.map(a => new FavAcctRow(a))];
  }

  rowClass = (row:RowClassParams<FavAcctRow>)=> {
    let c =  (this.OnSelect.observers.length) ?"cursor-pointer":"";
    if (!row.data.valid)
      c += " text-danger"
    return c;
  }

  onRowClick(event:CellClickedEvent<FavAcctRow>) {
    this.OnSelect.emit(event.data.account);
  }

  mfk = emptyMfk();
  description="";
  @ViewChild("editAccntDlg") editAccntDlg: TemplateRef<any>;
  Edit(row:FavAcctRow) {
    const add = !row;
    row = row || new FavAcctRow({
      id: 0,
      account: this.newFavorite,
      description: ""
    });
    this.mfk = Object.assign(emptyMfk() , Util.fromPartialMfk(emptyMfk() ,row.account));
    this.description = row?.description||"";
    this.modal.show({
      title: add ? "Add favorite account" : "Edit favorite account",
      cancelBtn: "Cancel",
      template: this.editAccntDlg,
      elementClass: "wide",
      okOnEnter: true,
      okBtnDisabled: ()=>!this.description,
      okBtnHelp: () => (!this.description)?"Description is required":""
    })
      .pipe(
        switchMap(res => {
          if (!res)
            return of(0);
          row.account = stringify(this.mfk);
          row.description = this.description;
          if (row.id) {
            return this.favSvc.updateAccnt(row);
          } else {
            return this.favSvc.addAccnt(row);
          }
        })
      )
      .subscribe(
        res => {
          if (res !== 0)
            this.favSvc.updateAccounts();
        },
        error => {
          this.modal.error(error).subscribe(()=>setTimeout(()=>this.Edit(row)))
        }
      );
  }

  Del(row:FavAcctRow) {
    this.modal.ask(
      "Remove from favorites",
      `Remove "${row.description}" from favorites?`
    )
      .pipe(
        switchMap(res => {
          return res ?
            this.favSvc.delAccnt(row)
            : of(0)
        })
      )
      .subscribe(
        res => {
          if (res !== 0)
            this.favSvc.updateAccounts();
        },
        error => {
          this.modal.error(error).subscribe()
        }
      );
  }
}