<ul class="text-nowrap mb-1" style="max-height: 25vh!important; overflow-y: auto;">
  <li *ngFor="let p of people">
    {{p.name}} ({{p.loginId}})
    <sup>
      <i class="fa fa-credit-card fa-sm cursor-pointer text-success me-1" title="Expenses"
        [routerLink]="routerLink('/xp')" [queryParams]="routerQuery('?_=$filter$personName$filterType=text%26type=contains%26filter='+p.name)"></i>
      <i class="fa fa-file-lines fa-sm cursor-pointer text-success me-1" title="Expense reports"
        [routerLink]="routerLink('/xpr')" [queryParams]="routerQuery('?_=$filter$personName$filterType=text%26type=contains%26filter='+p.name)"></i>
      <i class="fa fa-suitcase fa-sm cursor-pointer text-success me-1" title="Travel Expense Reports"
          [routerLink]="routerLink('/ter')" [queryParams]="routerQuery('?_=$filter$personName$filterType=text%26type=contains%26filter='+p.name)"></i>
      <i class="fa fa-folder-open fa-sm cursor-pointer text-success me-1" title="Files"
        [routerLink]="routerLink('/my-files/'+p.loginId)"></i>
      <i *ngIf="showRmFavs" class="fa fa-circle-xmark fa-sm cursor-pointer text-danger me-1" [title]="removeTitle" (click)="onRemovePerson.emit(p)"></i>
      <i *ngIf="showAddFavs && !p.favorite" class="fa fa-circle-plus fa-sm cursor-pointer text-success me-1" [title]="addTitle" (click)="onAddPerson.emit(p)"></i>
    </sup>
  </li>
</ul>
