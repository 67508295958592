import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '@uiowa/spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import { ModalModule } from "./modal/modal.module";
import { AgDatatableComponent, WorkflowComponent, AttachmentsComponent, UploadComponent, CommentsComponent, HubSelectComponent, MyPplSearchComponent, Account, FavAccnts, DateRangeComponent, OrgTreeComponent, PeopleComponent, PCardSearchComponent } from './components';
import { NgsContenteditableModule } from '@ng-stack/contenteditable';
import { IconTextComponent } from './components/iconText/iconText.component';
import { FavStar } from './components/fav-star/fav-star.component';
import { NgCompleterModule } from '@vldmr-bus/ng-completer';
import { PersonSearchComponent } from './components/person-search/person-search.component';
import { UiowaMfkModule } from '@uiowa/uiowa-mfk';
import { NavTilesComponent } from './components/nav-tiles/nav-tiles.component';
import { RouterModule } from '@angular/router';
import { DistributionsComponent } from './components/distributions/distributions.component';
import { ExpEditor } from './components/exp-editor/exp-editor.component';
import { EnterBlurDirective, FocusSelectDirective, FocusOnShowDirective } from './directives/utility';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    SpinnerModule,
    FontAwesomeModule,
    AgGridModule,
    ReactiveFormsModule,
    NgCompleterModule,
    UiowaMfkModule,
    NgsContenteditableModule,
    EnterBlurDirective,
    FocusSelectDirective,
    FocusOnShowDirective
  ],
  declarations: [
    AgDatatableComponent,
    DateRangeComponent,
    WorkflowComponent, 
    AttachmentsComponent,
    UploadComponent,
    HubSelectComponent,
    CommentsComponent,
    IconTextComponent,
    FavStar,
    PersonSearchComponent,
    PCardSearchComponent,
    MyPplSearchComponent,
    Account,
    FavAccnts,
    NavTilesComponent,
    DistributionsComponent,
    OrgTreeComponent,
    PeopleComponent,
    ExpEditor
  ],
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    SpinnerModule,
    FontAwesomeModule,
    ModalModule,
    AgGridModule,
    AgDatatableComponent,
    DateRangeComponent,
    WorkflowComponent,
    AttachmentsComponent,
    UploadComponent,
    HubSelectComponent,
    CommentsComponent,
    IconTextComponent,
    FavStar,
    PersonSearchComponent,
    PCardSearchComponent,
    MyPplSearchComponent,
    Account,
    FavAccnts,
    NavTilesComponent,
    DistributionsComponent,
    OrgTreeComponent,
    PeopleComponent,
    ExpEditor,
    EnterBlurDirective,
    FocusSelectDirective,
    FocusOnShowDirective
  ]
})
export class SharedModule {}
