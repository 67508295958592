import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Trip } from "../models/trip";
import { Observable, of } from "rxjs";
import { Dataset, DatasetCommand } from "../models/dataset";
import { Util } from "../util/util";
import { map, switchMap, tap } from "rxjs/operators";
import { City, Country, State } from "../models/trip-dest";
import { WfOffice } from "../models/wf-office";
import { Advisory } from "../models/advisory";
import { Passport } from "../models/passport";
import { TripDestBan } from "../models/trip-dest-ban";

@Injectable()
export class TripService {
  constructor(
    private http: HttpClient
  ) { 
    if (Object.keys(TripService._countries).length == 0) {
      this.fetchCountries().subscribe();
    }
  }

  private static _countries:{[key:string]:Country} = {}
  static get countries() {
    return Util.toArray<Country>(TripService._countries, ([k, v]) => <Country>{ code: k, name: v.name }, c=>c.name)
  }

  static getCountryName(code:string) {
    return TripService._countries[code]?.name??code;
  }

  // $$Implements SW.FE.TRIP.LIST_PAGE
  getAll(cmd: DatasetCommand): Observable<Dataset<Trip>> {
    let params = Util.CommandToHttpParams(cmd);
    return this.http.get<Dataset<Trip>>("api/trips/all", { params }).pipe(
      map(ds => {
        if (ds.data)
          ds.data.forEach(r => Trip.fixDates(r));
        return ds;
      })
    );
  }

  getTripDetails(tripId: number): Observable<Trip> {
    return this.http.get<Trip>(`api/trips/${tripId}`).pipe(
      map(x => Trip.fixDates(x))
    );
  }

  create(draft: Trip): Observable<number> {
    return this.http.post<number>(`api/trips/`, draft);
  }

  update(draft: Trip): Observable<Object> {
    return this.http.put(`api/trips/`, draft);
  }

  attachFromHub(xprid: number, fileid: number): Observable<number> {
    return this.http.post<number>(`api/trips/${xprid}/att/${fileid}`, null);
  }

  deleteFiles(ids: number[]): Observable<Object> {
    let params = Util.CommandToHttpParams({ ids });
    return this.http.delete(`api/person/att`, { params });
  }

  addComment(xpid: number, comment: string, prvt: boolean): Observable<Object> {
    return this.http.post(`api/trips/${xpid}/cmnt`, { comment, prvt });
  }

  validateTrip(tripId: number): Observable<string> {
    return this.http.get("api/trips/validate/" + tripId, {responseType: "text"});
  }

  getWfOffices(acc:string): Observable<WfOffice> {
    return this.http.get<WfOffice>("api/wf/offices/" + acc);
  }

  submitToWF(tripId: number, subDpt?: string, frstApprvr?: string): Observable<number> {
    return this.http.post<number>("api/trips/wf", {id:tripId, subDpt, frstApprvr});
  }

  cancelTrip(tripId: number): Observable<boolean> {
    return this.http.delete<boolean>("api/trips/"+tripId);
  }

  copyTrip(tripId: number): Observable<number> {
    return this.http.post<number>("api/trips/copy/"+tripId, null);
  }

  getCountry(code?:string): Observable<Country> {
    code = code||"USA";
    if (TripService._countries.hasOwnProperty(code)) {
      var country = TripService._countries[code];
      if (country.states?.hasOwnProperty("states") && Object.keys(country.states).length)
        return of(country);
      else
      return this.http.get<State[]>("api/trips/states/" + code).pipe(
          switchMap(states => {
            let c = TripService._countries[code];
            c.states = Object.fromEntries(states.map(s=>[s.code,s]));
            return of(c);
          })
        );
    } else 
    if (Object.keys(TripService._countries).length == 0) {
      return this.fetchCountries().pipe(
        switchMap(cs => {
          TripService._countries = cs;
          return this.getCountry(code);
        })
      );
    } else {
      throw Error(`Unknown country requested: "${code}"`)
    }
  }

  fetchCountries() {
    return this.http.get<{[key:string]:Country}>("api/trips/countries").pipe(
      tap(cs => TripService._countries = cs)
    );
  }

  getCities(stateCode: string): Observable<City[]> {
    return this.getCountry("USA").pipe(
      switchMap(c => {
        if (!c.states.hasOwnProperty(stateCode))
          throw Error(`Unknown state requested: "${stateCode}"`);
        let s = c.states[stateCode];
        if (s.cities?.length)
          return of(s.cities)
        else
          return this.http.get<City[]>(`api/trips/cities/${s.code}`).pipe(
            tap(c => s.cities = c)
          )
      })
    )
  }

  getCountriesStatesCities(countryCode:string, stateCode?:string): 
    Observable<{
        countries:Country[], 
        states:State[], 
        cities:City[]
      }> 
  {
      let result = {countries:[], states:[], cities:[]};
      return this.getCountry(countryCode).pipe(
      switchMap(c => {
        result.countries = TripService.countries;
        result.states = Util.toArray<State>(c.states, ([k, v]) => <State>{ code: k, name: v.name }, s => s.name);
        if (c.code == "USA" && stateCode)
          return this.getCities(stateCode)
        else
          return of([])
      }),
      switchMap(cs => {
        result.cities = cs;
        return of(result);
      })
    );
  }

  getAdvisory(stateCode: string): Observable<Advisory> {
    return this.http.get<Advisory>("api/trips/advisory/" + stateCode);
  }

  getPassports(tripId: number) {
    return this.http.get<Passport[]>(`api/trips/${tripId}/ppt`).pipe(
      map(ps => ps.map(p => Passport.fixDates(p)))
    );
  }

  savePassport(tripId: number, p: Passport) {
    if (p.id)
      return this.http.put(`api/trips/${tripId}/ppt`, p);
    else
      return this.http.post(`api/trips/${tripId}/ppt`, p);
  }

  deletePassport(tripId: number, id: number) {
    return this.http.delete(`api/trips/${tripId}/ppt/${id}`);
  }

  getTripDestBans(): Observable<TripDestBan[]> {
    return this.http.get<TripDestBan[]>("api/trips/bans").pipe(
      map(ps => ps.map(p => TripDestBan.fixDates(p)))
    );
  }

  saveCntryBan(b: TripDestBan) {
    if (b.id)
      return this.http.put(`api/trips/ban/${b.id}`, b);
    else
      return this.http.post(`api/trips/ban`, b);
  }

  deleteCntryBan(id: number) {
    return this.http.delete(`api/trips/ban/${id}`);
  }
}