<div class="h-100">
  <ag-datatable #datatable
    [columns]="cols"
    [rowData]="rows"
    [getRowClass]="rowClass"
    (ready)="onGridReady($event)"
    (rowClick)="onRowClick($event)"
    >
  </ag-datatable>
</div>

<ng-template #editDelTmpl let-row="row">
  <i class="fa fa-edit mx-1 cursor-pointer" title="Edit" (click)="$event.stopPropagation();Edit(row)"></i>
  <i class="fa fa-trash mx-1 cursor-pointer" title="Delete" (click)="$event.stopPropagation();Del(row)"></i>
</ng-template>

<ng-template #editAccntDlg>
    <uiowa-mfk-input [(mfk)]="mfk" class="col-12 p-1"></uiowa-mfk-input>
    <label class="col-12 p-1">Description:<input class="focused-initially" type="text" [(ngModel)]="description" placeholder="required"></label>
</ng-template>